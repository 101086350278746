<template>
    <a-layout-content class="Setting">
        <a-breadcrumb style="margin: 16px 24px">
            <a-breadcrumb-item>
                <router-link :to="{ path: '/' }" class="nav-link">
                    Trang chủ
                </router-link>
            </a-breadcrumb-item>
            <a-breadcrumb-item>{{ this.$route.name }}</a-breadcrumb-item>
        </a-breadcrumb>
        <div class="Setting" :style="{minHeight: 'calc(100vh - 120px)', backgroundColor: '#fff', margin: '15px 25px', padding: '15px' }">
            <a-layout style="padding: 24px 0; background: #fff">
                <a-layout-content :style="{ padding: '0 24px', minHeight: '280px' }">
                    <div class="content-header">
                        <h1>{{ this.$route.name }}</h1>
                        <div class="content-header__left">
                            <a-input-search
                                    placeholder="Tìm kiếm..."
                                    style="width: 200px; margin-left: 30px"
                                    @change="(e) => this.search = e.target.value"
                            />
                        </div>
                    </div>
                    <a-divider/>
                    <!--     Table question group       -->
                    <a-table :columns="columns" :dataSource="groupFilter" bordered :rowKey="(record, index) => record.id" :loading="loading" :pagination="true">
                        <template slot-scope="text">
                            <div :key="col">
                                <template>{{text}}</template>
                            </div>
                        </template>
                        <template slot="active" slot-scope="text, record">
                            <a-popconfirm title='Bạn có chắc chắn muốn thay đổi?' @confirm="() => publishChange(record)">
                                <a-checkbox :checked="record.active"/>
                            </a-popconfirm>
                        </template>
                        <template slot="operation" slot-scope="text, record">
                            <div class='editable-row-operations'>
                                <a-popconfirm title='Bạn có chắc chắn xoá?' @confirm="() => deleteSetting(record.id)">
                                    <a-button type="danger" :disabled="record.editable">Xóa</a-button>
                                </a-popconfirm>
                            </div>
                        </template>
                    </a-table>
                </a-layout-content>
            </a-layout>
        </div>
    </a-layout-content>
</template>

<script>
    import './_Setting.scss'
    import { RepositoryFactory } from "@/repository/RepositoryFactory";

    const ignoreSettingRepository = RepositoryFactory.get("ignoreSetting");

    export default {
        name: "TrainingIgnoreSetting",
        data() {
            return {
                visible: false,
                columns: [{
                    title: 'Id',
                    dataIndex: 'id',
                    width: '10%',
                    scopedSlots: { customRender: 'id' },
                }, {
                    title: 'Tên viết tắt',
                    dataIndex: 'salon.shortName',
                    width: '20%',
                    scopedSlots: { customRender: 'salonShortName' },
                }, {
                    title: 'Tên Salon',
                    dataIndex: 'salon.name',
                    width: '30%',
                    scopedSlots: { customRender: 'salon' },
                },{
                    title: 'Trạng thái',
                    dataIndex: 'active',
                    width: '10%',
                    scopedSlots: { customRender: 'active' },
                    filters: [{ text: 'Kích hoạt', value: 'true' }, { text: 'Không kích hoạt', value: 'false' }],
                    onFilter: (value, record) => record.active.toString() === value
                }, {
                    title: 'Hành động',
                    dataIndex: 'operation',
                    width: '30%',
                    scopedSlots: { customRender: 'operation' },
                }],
                data: [],
                loading: true,
                search: ''
            }
        },
        methods: {
            handleChangeOnActive (value, id, column) {
                const newData = [...this.data];
                const target = newData.filter(item => id === item.id)[0];
                if (target) {
                    target[column] = value;
                    this.data = newData;
                }
            },
            async publishChange(record) {
                record.active = !record.active;
                await this.saveSetting(record);
                this.handleChangeOnActive(record.active, record.id, 'active')
            },
            async saveSetting (record) {
                try {
                    this.loading = true;

                    let response = await ignoreSettingRepository.updateSetting(record.id, record);
                    if (!response.data.meta.success) throw new Error(response.data.meta.externalMessage)
                    const newData = [...this.data];
                    const target = newData.filter(item => record.id === item.id)[0];

                    if (target) {
                        delete target.editable;
                        this.data = newData;
                    }
                } catch (e) {
                    this.openNotification(e.message);
                } finally {
                    this.loading = false;
                }
            },
            openNotification (message) {
                this.$notification.open({
                    message: message
                });
            },
            async deleteSetting(id) {
                try {
                    this.loading = true;
                    let response = await ignoreSettingRepository.deleteSetting(id);
                    if (!response.data.meta.success) {
                        throw new Error(response.data.meta.externalMessage);
                    }
                    this.data = this.data.filter(item => item.id !== id);
                } catch (e) {
                    this.openNotification(e.message);
                } finally {
                    this.loading = false;
                }
            },
        },
        computed: {
            groupFilter: function () {
                return this.data.map(item => Object.assign({}, item))
                    .filter(item => item.salon.name.toUpperCase().includes(this.search.toUpperCase()) || item.salon.shortName.toUpperCase().includes(this.search.toUpperCase()))
            }
        },
        async created() {
            try {
                let response = await ignoreSettingRepository.getAll();
                // eslint-disable-next-line no-console
                if (!response.data.meta.success) {
                    this.data = [];
                    throw new Error(response.data.meta.externalMessage)
                }

                this.data = response.data.data
            } catch (e) {
                this.data = [];
                this.openNotification(e.message)
            } finally {
                this.loading = false;
            }
        }
    }
</script>

<style scoped>

</style>